import {
  PanelResolveType,
  type EventType,
  type ComponentClientSpecMapEntry,
} from '@wix/editor-platform-sdk-types';
import { installMembersArea } from '@wix/members-area-integration-kit';
import type { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

import BrokenAppImageUrl from 'assets/images/broken-app.png';

import { EManagementAction } from '../constants';
import {
  GROUPS_APP_DEFINITION_ID,
  GROUPS_DASHBOARD_URL,
} from '../../constants';

import { MigrationAssistant } from './migration-assistant';
import { EApplicationStatus } from './constants';
import { getApplicationStatus, isSilentInstallation } from './helpers';

export const editorReady: EditorReadyFn = async (
  sdk,
  appDefinitionId,
  options,
  flowAPI,
) => {
  const { initialAppData, origin } = options;
  const { applicationId } = initialAppData;
  const {
    errorMonitor,
    translations: { t },
  } = flowAPI;

  const migrator = new MigrationAssistant(applicationId, sdk, flowAPI);
  const status = await getApplicationStatus(applicationId, sdk, options);

  try {
    switch (status) {
      case EApplicationStatus.Installed:
        await migrator.migrate();
        await configureGroupPage();
        break;

      case EApplicationStatus.FirstInstall:
        if (!isSilentInstallation(origin.info?.type)) {
          // TODO: typing?
          await installMembersArea(
            sdk as Parameters<typeof installMembersArea>[0],
            { biData: options.biData },
          ).catch();
        }

        await renamePages();
        await migrator.install();
        await configureGroupPage();
        break;

      case EApplicationStatus.Broken:
        await openReinstallConfirmationDialog();
        return;
    }
  } catch (error) {
    errorMonitor.captureException(error as Error, {
      contexts: { file: { name: 'editor-ready/index.ts' } },
    });
    console.error('Wix Groups: editorReady', error);
  }

  sdk.addEventListener('openDashboard' as EventType, function () {
    openDashboard();
  });

  sdk.addEventListener('uninstall' as EventType, function () {
    sdk.application.uninstall(GROUPS_APP_DEFINITION_ID, {
      openConfirmation: true,
    });
  });

  sdk.addEventListener('widgetAdded', function (event) {
    return migrator.installComponent({
      id: event.detail.componentRef.id,
      widgetId: event.detail.widgetId,
    } as ComponentClientSpecMapEntry);
  });

  sdk.addEventListener('appActionClicked', function (data) {
    switch (data.detail.actionId) {
      case EManagementAction.MainAction:
        return openDashboard();

      case EManagementAction.CreateGroup:
        return openDashboard('templates');

      case EManagementAction.Setup:
        return openDashboard('settings');

      case EManagementAction.Addons:
        return sdk.editor.openModalPanel('', {
          width: 800,
          height: 165 * 3,
          url: getPanelUrl('Groups', 'Addons'),
          title: t('groups-web.settings.addons'),
        });

      default:
        return;
    }
  });

  async function openReinstallConfirmationDialog() {
    try {
      const result = await sdk.editor.openErrorPanel(GROUPS_APP_DEFINITION_ID, {
        shouldShowIllustration: true,
        illustration: BrokenAppImageUrl,
        headerText: t('groups-web.reinstall-dialog.title'),
        mainActionText: t('groups-web.reinstall-dialog.confirm'),
        secondaryActionText: t('groups-web.reinstall-dialog.cancel'),
        topDescriptionText: t('groups-web.reinstall-dialog.description'),
      });

      switch (result) {
        case PanelResolveType.MAIN_ACTION:
          await reinstall();
          return;

        case PanelResolveType.CLOSE_ACTION:
        case PanelResolveType.SECONDARY_ACTION:
        default:
          return;
      }
    } catch (error) {
      console.error('Wix Groups: reinstall', error);
      errorMonitor.captureException(error as Error);
    }
  }

  async function reinstall() {
    await sdk.application.uninstall(GROUPS_APP_DEFINITION_ID, {
      openConfirmation: false,
    });

    await sdk.tpa.add.application(GROUPS_APP_DEFINITION_ID, {
      appDefinitionId,
      shouldNavigate: true,
      showPageAddedPanel: true,
    });
  }

  function openDashboard(section?: 'settings' | 'templates') {
    const url = !section
      ? `/${GROUPS_DASHBOARD_URL}`
      : `/${GROUPS_DASHBOARD_URL}/${section}`;

    return sdk.editor.openDashboardPanel(GROUPS_APP_DEFINITION_ID, {
      url,
      closeOtherPanels: false,
    });
  }

  /**
   * setup custom state for Group Page
   * groupPage state behavior is described in manifest
   */
  async function configureGroupPage() {
    const pageRef = await sdk.tpa.getPageRefByTPAPageId(
      GROUPS_APP_DEFINITION_ID,
      {
        tpaPageId: 'group',
      },
    );

    await sdk.document.pages.setState(GROUPS_APP_DEFINITION_ID, {
      state: { groupPage: [pageRef] },
    });
  }

  /**
   * setup page titles
   */
  async function renamePages() {
    const [groupPageRef, groupListPageRef] = await Promise.all([
      sdk.tpa.getPageRefByTPAPageId(GROUPS_APP_DEFINITION_ID, {
        tpaPageId: 'group',
      }),
      sdk.tpa.getPageRefByTPAPageId(GROUPS_APP_DEFINITION_ID, {
        tpaPageId: 'groups',
      }),
    ]);

    await Promise.all([
      sdk.document.pages.rename(GROUPS_APP_DEFINITION_ID, {
        pageRef: groupPageRef,
        title: t('groups-web.initial.page-name.group-page'),
      }),
      sdk.document.pages.rename(GROUPS_APP_DEFINITION_ID, {
        pageRef: groupListPageRef,
        title: t('groups-web.initial.page-name.groups-page'),
      }),
    ]);
  }
};
